import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

document.addEventListener("turbolinks:load", () => {
  const input = document.querySelector("#phone") as HTMLInputElement;
  intlTelInput(input, {
    loadUtils: () => import("intl-tel-input/utils"),

    hiddenInput: (_telInputName) => ({
      phone: "registration[phone_number]",
    }),
    initialCountry: "us",
    nationalMode: false,
    separateDialCode: true,
  });
});
